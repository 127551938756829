import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en.json";
import hiTranslation from "./locales/hi.json";
import { LANGUAGE } from "@utils/constants";
import { useRouter } from "next/router";
import { useEffect } from "react";

const resources = {
  en: {
    translation: enTranslation,
  },
  hi: {
    translation: hiTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: LANGUAGE.ENGLISH, // default language
  fallbackLng: LANGUAGE.ENGLISH,
  interpolation: {
    escapeValue: false, // not needed for React
  },
});

export function useLocale() {
  const router = useRouter();

  useEffect(() => {
    i18n.changeLanguage(router.locale);
  }, [router.locale]);

  return null;
}

export default i18n;