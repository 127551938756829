import LangugageDropdown from "src/globalModule/components/languageDropdown";
import style from "../../styles/pujaTracking.module.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useRouter } from "next/router";
function AppBar({text, onClose}) {
    const router=useRouter();

    function clickHandler(){
        if(onClose){
            onClose();
        }
        else{

            router.back();
        }
    
    }

    return ( 
        <div className={style.kriyaNavbar}>
            <div className={style.navContainer}>
                <div className={style.navLeft}>
                    <div className={style.backArrow} onClick={clickHandler}><ArrowBackIcon style={{ fontSize: '24px', color: '#1A2B3C' }}/></div>
                    <div><h3>{text}</h3></div>
                </div>
                <div className={style.navRight}><LangugageDropdown/></div>
            </div>
        </div>
     );
}

export default AppBar;