// components/ErrorBoundary.js
import { ErrorBoundary } from "react-error-boundary";
import classes from "../styles/error.module.css";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { deviceDetect } from "react-device-detect";
import { postErrors } from "../queries/home.queries";
import { Typography } from "@mui/material";
import * as Sentry from "@sentry/nextjs";

// Error fallback component
const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const router = useRouter();

  useEffect(() => {
    const postErrorFunction = () => {
      // Add your error handling logic here
      const deviceData = deviceDetect();

      // const firstLine = extractFileLineInfo(error);
      // console.log(firstLine);

      const data = {
        date: new Date(Date.now()).toLocaleString("en-gb", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }),
        error: error.toString(),
        language: router.locale,
        pageURL: router.asPath,
        deviceOS: deviceData.os ? deviceData.os : deviceData.osName,
        deviceModel: deviceData.model ? deviceData.model : "",
        environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
      };

      Sentry.captureException(error);

      postErrors(data);

      const timer = setTimeout(() => {
        router.push("/home"); // Replace with your error page path
      }, 3000); // Redirect after 3 seconds

      return () => clearTimeout(timer); // Cleanup timer

      // router.push("/home");
    };

    postErrorFunction();
  }, [error]);

  return (
    <div role="alert" className={classes.container}>
      <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
        Something went wrong!
      </Typography>
      <Typography>Please stay with us.</Typography>

      {/* <pre>{error.message}</pre> */}
      <button onClick={resetErrorBoundary} className={classes.btn}>
        Try again
      </button>
    </div>
  );
};

export default function MyErrorBoundary({ children }) {
  const router = useRouter();
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app here if needed
        router.push("/home");
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
