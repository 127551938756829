import { createTheme } from "@material-ui/core";
const arcBlue = "#0B72B9";
const arcOrange =
  "linear-gradient(90deg, #FF9800 0%, #FF5757 100%, #FF5757 100%)";
const lightGray = "#F2F2F2";
const utsavWhite = "#FFFFFF";
const textSecondaryVeryLight = "#c2cdd1";
const textPrimary = "#323232";
const dividerColor = "#E2E2E2";

export default createTheme({
  palette: {
    common: {
      gray: `${lightGray}`,
      orange: `${arcOrange}`,
      darkOrange: "#FF5757",
      yellowBg: "#FFFEFA",
      utsavWhite:"#ffffff",
      utsavBlack:"#323232",
    },
    primary: {
      main: `#6F7D83`,
      secondary: `${textPrimary}`,
    },
    secondary: {
      main: "#FF9800",
      secondary: "#FFF1CB",
    },
    paper: {
      main: `${lightGray}`,
      secondary: `${utsavWhite}`,
    },
    btnColor: {
      main: `${arcOrange}`,
    },
    text: {
      primaryHead: "#1A2B3C",
      primaryDark: "#323232",
      secondaryDark: "#515969",
      secondaryLight: "#6F7D83",
      secondaryVeryLight: "#92A4AB",
    },
    stepper: {
      primary: "#27AE60",
    }
  },
  typography: {
    fontFamily: "inherit",
    // tab: {
    //   fontFamily: "Raleway",
    //   textTransform: "none",
    //   fontWeight: 700,
    //   fontSize: "1rem",
    // },
    // h2: {
    //   fontFamily: "Poppins",
    //   fontWeight: 700,
    //   fontSize: "2.5rem",
    //   color: `${arcBlue}`,
    //   lineHeight: 1.5,
    // },
    // h3: {
    //   fontFamily: "Poppins",
    //   fontSize: "2.5rem",
    //   color: `${arcBlue}`,
    // },
    // h4: {
    //   fontFamily: "Poppins",
    //   fontSize: "1.75rem",
    //   color: `${arcBlue}`,
    //   fontWeight: 700,
    // },
    // h6: {
    //   fontFamily: "Poppins",
    //   fontWeight: 500,
    //   color: `${arcBlue}`,
    //   lineHeight: 1,
    // },
    // subtitle1: {
    //   fontFamily: "Quicksand, sans-serif",
    //   fontSize: "1.25rem",
    //   fontWeight: 300,
    //   color: `${arcGray}`,
    // },
    // subtitle2: {
    //   fontFamily: "Quicksand, sans-serif",
    //   fontSize: "1.25rem",
    //   fontWeight: 300,
    //   color: `${arcWhite}`,
    // },
    // body1: {
    //   fontFamily: "'Quicksand', sans-serif",
    //   fontSize: "1.25rem",
    //   color: `${arcGray}`,
    //   fontWeight: 300,
    // },
    // caption: {
    //   fontFamily: "'Quicksand', sans-serif",
    //   fontSize: "1rem",
    //   fontWeight: 300,
    //   color: `${arcGray}`,
    // },
    linkText: {
      color: "#323232",
      fontFamily: "Poppins, sans-serif",
      fontSize: "16px",
      textDecoration: "none", // Disable underline
    },
    linkTextHighlighted: {
      color: "#FF9800",
      fontWeight:"bold",
      fontFamily: "Poppins, sans-serif",
      fontSize: "16px",
      textDecoration: "none", // Disable underline
    },
  },
  divider: {
    backgroundColor: dividerColor,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          // "& input": {
          //   color: "red", // change this to your desired color
          // },
          marginTop: "15px",
          marginBottom: "5px",
          "& input": {
            // Your existing styles here

            "&:-webkit-autofill": {
              "-webkit-text-fill-color": textPrimary,
              "-webkit-box-shadow": "0 0 0 1000px white inset",
            },
          },
          // Your custom styles here. For example:
          "& label.Mui-focused": {
            color: "#6F7D83",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: textSecondaryVeryLight,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: textSecondaryVeryLight,
            },
            "&:hover fieldset": {
              borderColor: textSecondaryVeryLight,
            },
            "&.Mui-focused fieldset": {
              borderColor: textSecondaryVeryLight,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: textSecondaryVeryLight,
            borderRadius: "10px",
          },
        },
      },
    },
  },
});

// overrides: {
//   MuiButton: {
//     root: {
//       color: "white", // Set the text color
//       backgroundColor:
//         "linear-gradient(90deg, #FF9800 0%, #FF5757 100%, #FF5757 100%)", // Set the background color
//     },
//   },
// },
