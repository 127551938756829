/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import { CacheProvider } from "@emotion/react";
import { StoreProvider } from "@store";
import "@styles/globals.css";
import createEmotionCache from "src/globalModule/styles/createEmotionCache";
import { useLocale } from "i18n";
import { WEBENGAGE_LICENSE_CODE } from "lib/webengage-analytics";
import Head from "next/head";
import Script from "next/script";
import PropTypes from "prop-types";
import { useEffect } from "react";
import LayoutManager from "src/globalModule/views/layoutManager";
import theme from "../src/globalModule/styles/theme";
import { setLanugage } from "../utilities/api/axiosInstance";
import dynamic from "next/dynamic";
import { FB_PIXEL_ID_1 } from "lib/fb-pixel-analytics";
import { GA_TRACKING_ID } from "lib/google-analytics";
import { GTM_ID } from "lib/google-tag-manager";
import MyErrorBoundary from "src/globalModule/components/error";

// import CssBaseline from '@mui/material/CssBaseline';
// import { ThemeProvider } from '@mui/material/styles';
const ThemeProvider = dynamic(() =>
  import("@mui/material/styles").then((md) => md.ThemeProvider)
);
const CssBaseline = dynamic(() => import("@mui/material/CssBaseline"));

const Analytics = dynamic(
  () => import("src/globalModule/components/Analytics"),
  {
    ssr: false,
  }
);

const ExternalScripts = dynamic(
  () => import("src/globalModule/components/ExternalScripts"),
  {
    ssr: false,
  }
);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

if (typeof global.navigator === "undefined") global.navigator = {};

export default function UtsavApp(props) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
    router,
  } = props;

  useLocale();

  useEffect(() => {
    if (typeof window !== "undefined") {
      try {
        sessionStorage.setItem("locale", router.locale);
      } catch (e) {
        console.log("Session storage is disabled");
      }
      setLanugage(router.locale);
    }
  }, [router.locale]);

  // useEffect(() => {
  //   sessionStorage.setItem("locale", router.locale);
  //   setLanugage(router.locale);
  // }, [router.locale]);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta
          name="facebook-domain-verification"
          content="ks0l85hu9go0ajairreo5ep3es0mle"
        />
      </Head>

      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <StoreProvider>
          <LayoutManager router={router} pageProps={pageProps}>
            <MyErrorBoundary>
              <Component {...pageProps} />
            </MyErrorBoundary>
          </LayoutManager>
        </StoreProvider>
      </ThemeProvider>

      {/* <ExternalScripts /> */}
      <Analytics router={router} />

      <Script
        id="scroll"
        strategy="lazyOnload"
      >{`window.history.scrollRestoration = "manual"`}</Script>

      {GTM_ID && (
        <Script
          strategy="beforeInteractive"
          id="gtag-manager"
          dangerouslySetInnerHTML={{
            __html: `setTimeout(function() {
                      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer', '${GTM_ID}');
                    }, 3000);`,
          }}
        />
      )}

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      {GA_TRACKING_ID && (
          <Script
            id="gtag-link"
            strategy="beforeInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
        ) && (
          <Script
            id="gtag-init"
            strategy="beforeInteractive"
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${GA_TRACKING_ID}', {
                      page_path: window.location.pathname,
                  });`,
            }}
          />
        )}
      {/* Global Site Code Pixel - Facebook Pixel */}
      {FB_PIXEL_ID_1 && (
        <Script
          id="fb-pixel"
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: `setTimeout(function() {
                      !function(f,b,e,v,n,t,s)
                      {if(f.fbq)return () => {};n=f.fbq=function(){n.callMethod?
                      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                      n.queue=[];t=b.createElement(e);t.async=!0;
                      t.src=v;s=b.getElementsByTagName(e)[0];
                      s.parentNode.insertBefore(t,s)}(window, document,'script',
                      'https://connect.facebook.net/en_US/fbevents.js');
                      fbq('init', ${FB_PIXEL_ID_1});
                      fbq('track', 'PageView');
                    }, 3000);`,
          }}
        />
      )}

      <Script
        id="webengage-analytics"
        type="text/javascript"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `var webengage;
                  ! function(w, e, b, n, g) {
                    function o(e, t) {
                      e[t[t.length - 1]] = function() {
                        r.__queue.push([t.join("."), arguments])
                      }
                    }
                    var i, s, r = w[b],
                      z = " ",
                      l = "init options track screen onReady".split(z),
                      a = "feedback survey notification".split(z),
                      c = "options render clear abort".split(z),
                      p = "Open Close Submit Complete View Click".split(z),
                      u = "identify login logout setAttribute".split(z);
                    if (!r || !r.__v) {
                      for (w[b] = r = {
                          __queue: [],
                          __v: "6.0",
                          user: {}
                        }, i = 0; i < l.length; i++) o(r, [l[i]]);
                      for (i = 0; i < a.length; i++) {
                        for (r[a[i]] = {}, s = 0; s < c.length; s++) o(r[a[i]], [a[i], c[s]]);
                        for (s = 0; s < p.length; s++) o(r[a[i]], [a[i], "on" + p[s]])
                      }
                      for (i = 0; i < u.length; i++) o(r.user, ["user", u[i]]);
                      setTimeout(function() {
                        var f = e.createElement("script"),
                          d = e.getElementById("webengage-analytics");
                        f.type = "text/javascript",
                          f.async = !0,
                          f.src = ("https:" == e.location.protocol ? "https://widgets.in.webengage.com" : "http://widgets.in.webengage.com") + "/js/webengage-min-v-6.0.js";
                          if (d){
                            d.parentNode.insertBefore(f, d);
                          }
                      })
                    }
                  }(window, document, "webengage");

                  webengage.init('${WEBENGAGE_LICENSE_CODE}')
                  webengage.options("safariWebPush",true)`,
        }}
      />
    </CacheProvider>

    // This will append pwaupdate component, which will register your pwabuilder-sw.js file as the service worker.
  );
}

UtsavApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
