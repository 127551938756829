export const WEBENGAGE_LICENSE_CODE = process.env.NEXT_PUBLIC_WEBENGAGE_LICENSE_CODE;

export const event = (name, options = {}) => {
  if (!window.webengage) return () => {};

  window.webengage.track(name, options);
};

export const user = (name, attribute, uid) => {
  if (!window.webengage) return () => {};

  window.webengage.user.login(uid);
  window.webengage.user.setAttribute(name, attribute);
};