import { createContext, useReducer, useMemo } from "react";
import dynamic from "next/dynamic";
import guestUserData from "@utils/user/guestUserData";
import { setHeaderAccessToken } from "@utils/api/axiosInstance";
// import ReduxStoreProvider from "@utils/reduxToolkit/provider";

const ReduxStoreProvider = dynamic(() => import("@utils/reduxToolkit/provider"));

export const Store = createContext();

const initialState = {
  user: {
    name: "--",
    uid: null,
    authType: null,
    accessToken: null,
  },
  lang: "en",
};

function reducer(state, action) {
  // console.log("arnab", state, action);

  switch (action.type) {
    case "SET_DATA":
      if (
        action.payload?.userData &&
        action.payload?.userData?.uid === state.user?.uid
      )
        return state;

      if (action.payload?.userData?.accessToken) {
        // console.log("Setting header");
        setHeaderAccessToken(action.payload?.userData?.accessToken);
      }

      const newState = { user: guestUserData, lang: "en" };

      if (action.payload.userData) newState.user = action.payload.userData;
      if (action.payload.lang) newState.lang = action.payload.lang;

      // console.log("Setting newState => ", newState);
      return newState;

    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => {
    return {
      state,
      dispatch,
    };
  }, [state]);

  return (
    <Store.Provider value={value}>
      <ReduxStoreProvider>
        <>{props.children}</>
      </ReduxStoreProvider>
    </Store.Provider>
  );
}
