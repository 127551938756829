import { printInConsole } from "@utils/devtools/utils";
import axios from "axios";
// import { google } from "googleapis";

export async function getHomeDetails() {
  const reqOptions = {
    method: "GET",
    url: encodeURI("user/web/home/details"),
  };

  const response = await axios(reqOptions).catch((error) => {
    printInConsole(error);
  });

  if (response?.data?.success) return response.data.response;

  return null;
}

export async function postErrors(data) {
  const response = await axios.post("/api/updateGoogleSheet", {
    data: data,
  });

  console.log("Response from postErrors", response);
}
