import baseUrl from "@utils/BASE-URL";
import axios from "axios";

const instance = axios.create({
  baseURL: baseUrl,
});

// Alter defaults after instance has been created
if (process.env.NEXT_PUBLIC_ENVIRONMENT === "DEVELOPMENT") {
  instance.defaults.headers.common["x-be-dev-token"] =
    process.env.NEXT_PUBLIC_BACKEND_DEV_TOKEN;
} else if (process.env.NEXT_PUBLIC_ENVIRONMENT === "UAT") {
  instance.defaults.headers.common["x-be-uat-token"] =
    process.env.NEXT_PUBLIC_BACKEND_UAT_TOKEN;
}

export default instance;

export const setHeaderAccessToken = (accessToken) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};

export const resetHeaderAccessToken = () => {
  delete instance.defaults.headers.common["Authorization"];
};

export const setLanugage = (language) => {
  instance.defaults.headers.common["language"] = language;
  //console.log("arnab language 29", language);
};

export const resetLanguage = () => {
  delete instance.defaults.headers.common["language"];
};
