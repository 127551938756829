export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const pageview = (url) => {
  if (!window.dataLayer) return () => {};

  window.dataLayer.push({
    event: "PageView",
    page: url,
  });
};

export const event = (name, options = {}) => {
  if (!window.dataLayer) return () => {};

  window.dataLayer.push({
    event: name,
    ...options,
  });
}