export const VIEWTYPES = {
  HASHTAG: 200,
  LINEARLIST: 300,
  CREATEPOST: 400,
  CAROUSEL: 500,
  GRIDLIST: 600,
  GRIDLIST1: 650,
  TRENDING_FEED: 700,
  FESTIVAL_TYPE: 750,
  CLIPS_HOME: 800,
  CLIPS_PROFILE: 850,
  POST: 900,
  TIMER: 1000,
  UTSAV_CLIPS: 1100,
  YOUTUBE_CLIPS: 1200,
  FESTIVAL_TIMELINE: 1400,
  RELATEDPOSTS: 1600,
};

export const COMMUNITY_MEMBER_ROLES = {
  OWNER: "599",
  ADMIN: "699",
  MODERATOR: "799",
  GENERAL: "899",
  INVITED: "999",
  REQUESTED: "1099",
};

export const GENDER = {
  MALE: "333",
  FEMALE: "444",
  OTHERS: "555",
};

export const FEED_TYPES = {
  TEXT: "2199",
  IMAGE: "2299",
  VIDEO: "2399",
  BLOG: "2499",
  POLL: "2599",
  FAQ: "2699",
  DP: "2799",
  COVER: "2899",
  LINK: "2999",
};

export const AUTH_TYPE = {
  EMAIL: "1566",
  GOOGLE: "1666",
  PHONE: "1766",
};

export const SMS_TEMPLATE = {
  ANDROID: "14001",
  WEBSITE: "14002",
};

export const COMMUNITY_SECURITY_TYPES = {
  PUBLIC: "1299",
  PRIVATE: "1399",
  RESTRICTED: "1499",
};

export const NOTIFICATION_TYPES = {
  LIKE: "4099",
  COMMENT: "4199",
  COMMENT_REPLY: "4299",
  COMMENT_LIKE: "4399",
  COMMENT_REPLY_LIKE: "4499",
  TAG_POST: "4599",
  TAG_COMMENT: "4699",
  COMMUNITY_UPVOTE: "4799",
  PROFILE_FOLLOW: "4899",
  FOLLOWERS: "4999",
  TAG_REPLY: "5099",
  REQUEST_TO_JOIN: "5199",
  REQUEST_TO_JOIN_APPROVED: "5299",
  REQUEST_TO_JOIN_REJECTED: "5399",
  JOIN_NOTIF_TO_FOLLOWERS: "5499",
  NAME_CHANGED: "5599",
  DONATION: "5699",
  UPI_ADDED: "5799",
  TRANSFER_SUCCESS_NOTIF: "5899",
  TRANSFER_FAILURE_NOTIF: "5999",
  VERIFICATION: "6099",
  INVITATION_TO_JOIN_ACCEPTED: "6199",
  INVITATION_TO_JOIN_REJECTED: "6299",
  INVITATION: "6399",
  COMMUNITY_POST: "6499",
};

export const TAG_TYPE = {
  USER_TAGGING: "1234",
  COMMUNITY_TAGGING: "2345",
};

export const DONATION_TYPES = {
  RECEIVED: "100",
  TRANSFER_SUCCESS: "200",
  TRANSFER_FAILED: "400",
  COMMISSION_PERCENTAGE: 2,
};

export const EVENT_TYPE = {
  DONATION_CAMPAIGN: "0a03b70dc3974ac39b0b5b6f05416975",
  PUJA: "0c32371f6b2c4ce688a5a5ee06ccf9ce",
  LIVE: "4155446a35f74dfebede386e805cbb79",
  GENERAL: "768fd5a108d148cb9e4129945f0846b3",
};

export const KRIYA_STATUS = {
  INITIATED: "10001",
  CAPTURED: "10002",
  TRANSFERRED: "10003",
  PUJA_OFFERED_DELAYED: "10003.50",
  PUJA_OFFERED: "10004",
  PUJA_OFFERED_WITH_VIDEO: "10004.50",
  PRASAD_PACKED: "10005",
  ORDER_PLACED: "10006",
  PRASAD_SHIPPED_DELAYED: "10006.50",
  PRASAD_SHIPPED: "10007",
  DELIVERED_DELAYED: "10007.50",
  OUT_FOR_DELIVERY: "10007.75",
  DELIVERED: "10008",
  CANCELLED: "10009",
  TESTING: "10016",
};

export const BLOG_ADMINS = [
  "arnabbhakta956@gmail.com",
  "vishal00shaw@gmail.com",
  "l9U2s1k16xQWiudcspTqJBOhX6X2",
  "PdfZ65WOimMQ0yoUv1qtXDMW4dq1",
  "tVKc8NbA1pMvZNOZPsTFRt9eaLf2",
  "UR6MPRoL1nNJmGiqgGQsSZacoeF2",
  "htJHHPTN9CgdJrrd2ULUDHVhHHx1",
  "Cm0XEbEXSgg3OdUn3v2FDbx9f9q1",
  "iGQUIZqwRTaf4Tghn2dd0AoIM1c2",
  "snepj7hvX6g0tSCGc0ikpLYP3HX2",
  "KWyoSL1YPee4KAvwiFB7a4oYrCe2",
  "l9U2s1k16xQWiudcspTqJBOhX6X2",
];

export const UTSAV_ADMINS = {
  UTSAV_ADMIN: "11001",
  BLOG_POSTERS: "11002",
};

// GET KEY BY VALUE FUNCTIONS

export function getMemberRole(value, lang) {
  const role = Object.keys(COMMUNITY_MEMBER_ROLES).find(
    (key) => COMMUNITY_MEMBER_ROLES[key] === value
  );
  if (role === "OWNER")
    return lang == LANGUAGE.ENGLISH ? "Founder" : "संस्थापक";
  if (role === "ADMIN") return lang == LANGUAGE.ENGLISH ? "Admin" : "प्रशासक";
  if (role === "MODERATOR")
    return lang == LANGUAGE.ENGLISH ? "Moderator" : "मॉडरेटर";
  if (role === "GENERAL")
    return lang == LANGUAGE.ENGLISH ? "General" : "सामान्य";
  if (role === "INVITED")
    return lang == LANGUAGE.ENGLISH ? "Invited" : "आमंत्रित";
  if (role === "REQUESTED")
    return lang == LANGUAGE.ENGLISH ? "Requested" : "अनुरोधित";

  return role;
}

export function getGender(value) {
  return Object.keys(GENDER).find((key) => GENDER[key] === value);
}

export const LANGUAGE = {
  ENGLISH: "en",
  HINDI: "hi",
};

export const PAYMENT_MODES = {
  COD: "13001",
  ONLINE: "13002",
};

export const DELIVERY_AGENT = {
  XPRESSBEES: "16001",
  ECOMEXPRESS: "16002",
  OTHER: "16003",
};

export const CONTACT_NUMBER = {
  number: 7439888677,
};

export const UTSAV_WHATSAPP_NUMBER = {
  number: 916291087857,
};

export const PANDIT_AVAILABILITY = {
  OFF: "22221",
  FORM_FILLUP: "22222",
  CALL_PANDIT: "22223",
};

export const WEB_ENGAGE = {
  EVENT_NAMES: {
    SCREEN_VIEWED: "Screen_Opened",
    ITEM_SEARCHED: "Item_Searched",
    ITEM_CLICKED: "Item_Clicked",
    TEXT_CLICKED: "Text_Clicked",
    BUTTON_CLICKED: "Button_Clicked",
    DIALOG_VIEWED: "Dialog_Opened",
    DIALOG_CLOSED: "Dialog_Closed",
    BOX_CHECKED: "Box_Checked",
    MEDIA_VIEWED: "Media_Opened",
    OTP_SUBMITTED: "OTP_Submitted",
    KRIYA_REGISTRATION_SUCCESS: "Kriya_Registration_Success",
    KRIYA_REGISTRATION_FAILURE: "Kriya_Registration_Failure",
    USER_AUTH_SUCCESS: "User_Auth_Success",
    USER_REGISTRATION_SUCCESS: "User_Registration_Success",
    PURCHASE: "purchase",
    SIGN_UP: "sign_up",
    KRIYA_SEARCHED: "Kriya_Searched",
    KRIYA_SEARCH_FAILED: "Kriya_Search_Failed",
    BLOG_SHARED: "Blog_Shared",
    POST_SHARED: "Post_Shared",
  },

  EVENT_PROPERTIES: {
    SCREEN_NAME: "screen_name",
    TEXT_NAME: "text_name",
    BUTTON_NAME: "button_name",
    ITEM_NAME: "item_name",
    MEDIA_TYPE: "media_type",
    CLICKED_FROM: "clicked_from",
    CLICK_ELEMENT: "click_element",
    PAYMENT_MODE: "event_payment_mode",
    KEYWORD: "keyword",
    COMMUNITY_NAME: "community_name",
    KRIYA_NAME: "kriya_name",
    SUB_KRIYA_NAME: "sub_kriya_name",
    AMOUNT: "event_registration_amount",
    MAIN_PUJA_PRICE: "main_puja_price",
    BHET_PUJA_PRICE: "bhet_puja_price",
    TOTAL_PUJA_PRICE: "total_puja_price",
    BHET_COUNT: "bhet_count",
    FAQ_NAME: "faq_name",
    STATE: "state",
    PINCODE: "pincode",
    RATING: "rating",
    AUTH_METHOD: "auth_method",
    EMAIL_ADDED: "email_added",
    CONTACT_ADDED: "contact_added",
    CATEGORY_NAME: "category_name",
    USER_TYPE: "user_type",
    EMAIL: "email",
    PHONE_NUMBER: "phone_number",
    USER_NAME: "name",
    USER_ID: "user_id",
    KRIYA_TYPE: "kriya_type",
    CHECK_STATUS: "check_status",
    REGISTRATION_ID: "registration_id",
    EVENT_URL_EXTENSION: "event_url_extension",
    SUB_EVENT_URL_EXTENSION: "sub_event_url_extension",
    EVENT_DEITY_TYPE: "event_deity_type",
    BLOG_NAME: "blog_name",
    POST_ID: "post_id",
    SHARE_MEDIUM: "share_medium",
    OFFERING_NAME_COUNT: "offering_name_count",
    IS_ADDRESS_GIVEN: "is_address_given",
    BLOG_CATEGORY_NAME: "blog_category_name",
  },

  SCREEN_NAMES: {
    EVENT_LIST: "Kriya List",
    EVENT_DETAILS: "Kriya Details",
    EVENT_DETAILS_POPUP: "Kriya Details Popup",
    SUB_EVENT_LIST: "Sub Kriya List",
    EVENT_REGISTRATION_MAIN_CARD: "Kriya Registration Login",
    EVENT_REGISTRATION_OTP_CARD: "Kriya Registration OTP",
    EVENT_REGISTRATION_BHET_DAAN_CARD: "Kriya Registration Bhet Daan",
    EVENT_REGISTRATION_DETAILS_CARD: "Kriya Registration Details",
    EVENT_REGISTRATION_WISH_CARD: "Kriya Registration Wish",
    EVENT_REGISTRATION_ADDRESS_CARD: "Kriya Registration Address",
    EVENT_REGISTRATION_SUCCESS: "Kriya Registration Success",
    EVENT_REGISTRATION_UPDATE_ACTIVITY: "Kriya Registration Update Details",
    NON_SERVICEABLE_PINCODE_MODAL: "Non-serviceable Pincode",
    COD_INSTRUCTIONS_MODAL: "COD Instructions",
    COD_INSTRUCTIONS_MODAL_1: "COD Instructions 1",
    COD_INSTRUCTIONS_MODAL_2: "COD Instructions 2",
    COD_INSTRUCTIONS_MODAL_3: "COD Instructions 3",
    KRIYA_REVIEW_FORM: "Kriya Review Form",
    LANDING_PAGE: "Landing Page",
    CREATE_PROFILE: "Create Profile",
    DIALOG_GUEST: "Guest Login/Signup",
    CONTACT_ONBOARDING_SCREEN: "Contact Onboarding",
    OTP_ONBOARDING_SCREEN: "OTP Onboarding",
    EMAIL_SIGNUP_ONBOARDING_SCREEN: "Email Sign Up Onboarding",
    EMAIL_LOGIN_ONBOARDING_SCREEN: "Email Sign In Onboarding",
    DIALOG_KRIYA_PAYMENT: "Kriya Payment",
    BLOG_SCREEN: "Blog Screen",
    POST_SCREEN: "Post Screen",
    HOME_BLOG: "Blogs List",
    BLOG_CATEGORY_PAGE: "Blogs Category List",
    BLOG_ONBOARDING_SCREEN: "Blog Onboarding",
  },

  TEXT_NAMES: {
    TOTAL_BOOKINGS: "Total Bookings",
    CHANGE_NUMBER: "Change Number",
    RESEND_OTP: "Resend OTP",
    DOWNLOAD_RECEIPT: "Download Receipt",
    SHOW_MORE_REVIEWS: "Show More Reviews",
    FOOTER_VISIT_UTSAV: "Footer Visit Utsav",
    FOOTER_BLOGS: "Footer Blogs",
    FOOTER_KRIYAS: "Footer Kriyas",
    MORE_OPTIONS: "More Options",
    FORGOT_PASSWORD: "Forgot Password",
  },

  BUTTON_NAMES: {
    POSITIVE: "Positive",
    NEGATIVE: "Negative",
    KRIYA_BOOK: "Kriya Book",
    SUB_KRIYA_BOOK: "Sub Kriya Book",
    DIALOG_BOOK: "Dialog Book",
    CONTACT_SUPPORT: "Contact Support",
    DOWNLOAD_APP: "Download App",
    SHARE_WHATSAPP: "Share Whatsapp",
    SHARE_FACEBOOK: "Share Facebook",
    SHARE_TWITTER: "Share Twitter",
    SHARE_INSTAGRAM: "Share Instagram",
    SHARE_YOUTUBE: "Share Youtube",
    SHARE_GENERAL: "Share General",
    SHARE_RECEIPT: "Share Receipt",
    HELPLINE_NUMBER: "Helpline Number",
    DESCRIPTION_SEE_MORE: "Description See More",
    NAV_LOGIN: "Nav Login",
    NAV_BLOGS: "Nav Blogs",
    NAV_APP_DOWNLOAD: "Nav App Download",
    DOWNLOAD_APP_1: "Download App 1",
    DOWNLOAD_APP_2: "Download App 2",
    DOWNLOAD_APP_3: "Download App 3",
    DOWNLOAD_APP_4: "Download App 4",
    DOWNLOAD_APP_5: "Download App 5",
    LOGIN_GUEST: "Login Guest",
    SIGN_IN_EMAIL: "Sign In Email",
    SIGN_UP_EMAIL: "Sign Up Email",
    SIGN_IN_GOOGLE: "Sign In Google",
    SIGN_UP_GOOGLE: "Sign Up Google",
    GET_OTP: "Get OTP",
    USER_REGISTER: "User Register",
    SUBMIT_ADDRESS: "Submit Address",
    ADD_BHET_DAAN: "Add Bhet Daan",
    PANDIT_FORM_SUBMIT: "Pandit Form Submit",
  },

  ITEM_NAMES: {
    KRIYA_CARD: "Kriya Card",
    COMMUNITY_CARD: "Community Card",
    FAQ_BOX: "FAQ Box",
    COMMUNITY_BOX: "Community Box",
    KRIYA_STATUS_BOX: "Kriya Status Box",
    REVIEW_BOX: "Review Box",
    LOCATION: "Location",
    SUB_KRIYA_SMALL_CARD: "Small Sub Kriya Card",
    BLOG_CATEGORY: "Blog Category",
    PANDIT_BANNER: "Pandit Banner",
  },

  CLICK_ELEMENTS: {
    BUTTON: "Button",
    IMAGE: "Image",
    NAME: "Name",
    COMMUNITY: "Community",
  },

  CLICKED_FROMS: {
    KRIYA_BOX: "Kriya Box",
    BOTTOM_BUTTON: "Bottom Button",
  },

  PAYMENT_MODES: {
    PREPAID: "PREPAID",
    COD: "COD",
  },

  AUTH_METHODS: {
    EMAIL: "Email",
    PHONE: "Phone",
    GOOGLE: "Google",
  },

  MEDIA_TYPES: {
    IMAGE: "Image",
    VIDEO: "Video",
    LINK: "Link",
    DOCUMENT: "Document",
  },

  DECISION: {
    YES: "Yes",
    NO: "No",
  },

  OPT_IN: {
    EMAIL: "email_opt_in",
    SMS: "sms_opt_in",
    WHATSAPP: "whatsapp_opt_in",
    PUSH: "push_opt_in",
    IN_APP: "in_app_opt_in",
  },

  USER_TYPE: {
    NEW: "New",
    OLD: "Old",
  },

  KRIYA_TYPE: {
    DIGITAL_PUJA: "Digital Puja",
    NORMAL_PUJA: "Normal Puja",
  },

  SHARE_MEDIUM: {
    WHATSAPP: "whatsapp",
    FACEBOOK: "facebook",
    TWITTER: "twitter",
    INSTAGRAM: "instagram",
    YOUTUBE: "youtube",
    GENERAL: "general",
  },
};

export const REFERRAL = {
  BONUS: 200,
};

export const HTTP_STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  BLOCKED: 405,
};

export const HOME_VIEW_TYPES = {
  ELEMENT_KRIYA_BANNER: 100001,
  ELEMENT_KRIYA_LIST: 100002,
  ELEMENT_BLOG: 100003,
  ELEMENT_REVIEW: 100005,
  //ELEMENT_REVIEW: 90012,
  ELEMENT_DEMO_VIDEO: 100006,
};

export const RECEIPT_VIEW_TYPE = {
  ELEMENT_RECEIPT: 120001,
  ELEMENT_BANNER: 120002,
  ELEMENT_EMPTY: 120003,
  ELEMENT_THIN_BANNER: 120004,
};
