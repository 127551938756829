const appDownloadUrl = process.env.NEXT_PUBLIC_APP_DOWNLOAD_URL || "";

let baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
let appBaseUrl = "https://localhost:3000";
let domain = "localhost";

if (process.env.NEXT_PUBLIC_APP_HOSTNAME) {
  appBaseUrl = process.env.NEXT_PUBLIC_APP_HOSTNAME;
}

if (process.env.NEXT_PUBLIC_APP_DOMAIN) {
  domain = process.env.NEXT_PUBLIC_APP_DOMAIN;
  // domain = "." + process.env.NEXT_PUBLIC_APP_DOMAIN;
}

export default baseUrl;
export { appBaseUrl, appDownloadUrl, domain };
